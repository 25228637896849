import SearchFullScreenModal from "@/components/shared/modalSearchFullscreen";
import { SearchForm } from "@/components/shared/SearchForm";
import { Tag } from "lucide-react";
import Link from "next/link";
import { useCallback, useState } from "react";
import { getLocalizedPath } from "../shared/formatURL";

interface SearchModalProps {
  showSearchModal: boolean;
  setShowSearchModal: React.Dispatch<React.SetStateAction<boolean>>;
  locale: any;
}

const SearchModal: React.FC<SearchModalProps> = ({
  showSearchModal,
  setShowSearchModal,
  locale,
}) => {
  const [searchQuery, setSearchQuery] = useState<string>("");

  return (
    <SearchFullScreenModal showModal={showSearchModal} setShowModal={setShowSearchModal}>
      <div className="w-full overflow-hidden border-0 border-[#3C3C3C] px-4 py-0.5 md:max-w-md md:rounded-2xl">
        <div className="flex flex-row items-center justify-center bg-transparent text-center md:px-16">
          <SearchForm
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            locale={locale}
            formClassName={"relative mr-2 w-full md:block"}
            inputClassName={
              "w-full rounded-md border-[0.5px] border-[#3C3C3C] bg-[#2B2D31] px-4 py-[12px] pl-4 pr-10 text-sm text-white placeholder-white placeholder-opacity-75 focus:outline-none"
            }
            buttonClassName={"absolute right-2 top-1/2 -translate-y-1/2 transform text-white"}
            autoFocus={true}
          />
          <button
            onClick={() => setShowSearchModal(false)}
            className="w-auto items-center justify-center whitespace-nowrap rounded-md border-[0.5px] border-[#3C3C3C] bg-[#2B2D31] px-2 py-[13px] text-white hover:brightness-105"
          >
            <Link prefetch={false} href={getLocalizedPath("tags")} className="flex flex-row">
              <Tag className="my-auto mr-0.5 h-[14px] text-[#E53155]" />
              <div className="mr-1 text-sm">{locale.tags}</div>
            </Link>
          </button>
          {/* <button className="w-auto py-[12px] px-2 items-center justify-center rounded-md border-[0.5px] border-[#3C3C3C] bg-[#2B2D31] text-white whitespace-nowrap hover:brightness-105">
            <a
              href="http://tinyurl.com/3r36dhst"
              target="_blank"
              rel="noopener noreferrer"
              className="flex flex-row"
            >
              <Gamepad2 className="my-auto mr-0.5 h-4 text-[#E53155]" />
              <div className="text-sm mr-1">{locale.games}</div>
            </a>
          </button> */}
        </div>
      </div>
    </SearchFullScreenModal>
  );
};

export function useSearchModal({ locale }: { locale: any }) {
  const [showSearchModal, setShowSearchModal] = useState(false);

  const SearchModalComponent = useCallback(
    () => (
      <SearchModal
        showSearchModal={showSearchModal}
        setShowSearchModal={setShowSearchModal}
        locale={locale}
      />
    ),
    [showSearchModal, setShowSearchModal],
  );

  return {
    setShowSearchModal,
    SearchModal: SearchModalComponent,
  };
}
