"use client";
import Link from "next/link";
import { usePathname } from "next/navigation"; // Assuming this is your custom hook
import { getLocalizedPath } from "../shared/formatURL";
import { useState, useEffect, useCallback } from "react";
import { useGlobalContext } from "../../app/context/store";
import { LanguagePopover } from "./languagePopover";

export const allowedPaths = [
  "/affiliates",
  "/create",
  "/post",
  "/faq",
  "/tags",
  "/privacy",
  "/terms",
  "/upgrade",
  "/user/affiliate",
  "/user/premium",
  "/2257",
  "/contact",
  "/billing",
  "/blog",
  "/credits",
  "/reward-calendar",
];

export default function Footer({ locale }: { locale: any }) {
  const pathname = usePathname();
  const [showFooter, setShowFooter] = useState(true);
  const { user } = useGlobalContext();

  // Simple debounce function
  const debounce = (func: () => void, timeout: number) => {
    let timer: number | undefined;
    return () => {
      clearTimeout(timer);
      timer = window.setTimeout(func, timeout);
    };
  };

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    const atBottom =
      window.innerHeight + currentScrollY >= document.body.offsetHeight;

    // Check if the current path is in allowedPaths
    // const isAllowedPath = allowedPaths.includes(pathname);
    const isAllowedPath = allowedPaths.some((allowedPath) =>
      pathname.startsWith(allowedPath),
    );
    // console.log(isAllowedPath);
    setShowFooter((isAllowedPath && atBottom) || currentScrollY <= 0);
  }, [pathname]);

  useEffect(() => {
    const debouncedHandleScroll = debounce(handleScroll, 100);
    window.addEventListener("scroll", debouncedHandleScroll);
    window.addEventListener("touchmove", debouncedHandleScroll);

    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
      window.removeEventListener("touchmove", debouncedHandleScroll);
    };
  }, [handleScroll]);

  function subRedditName() {
    // Switch based on a specific environment key
    switch (process.env.NEXT_PUBLIC_BASE_URL) {
      case "https://www.createporn.com":
        return "/r/createAIart";
      case "https://www.createhentai.com":
        return "/r/createAIhentai";
      case "https://www.createaishemale.com":
        return "/r/createAIshemale";
      case "https://www.createaiasian.com":
        return "/r/createAIasian";
      case "https://www.createaifurry.com":
        return "/r/createAIfurry";
      case "https://www.createaigayporn.com":
        return "/r/createAIgayporn";
      default:
        return "createAIart"; // Default subreddit
    }
  }

  function twitterLink() {
    // Switch based on a specific environment key
    switch (process.env.NEXT_PUBLIC_BASE_URL) {
      case "https://www.createporn.com":
        return "https://x.com/CreateAIArts";
      case "https://www.createhentai.com":
        return "https://x.com/CreateAIHentai";
      case "https://www.createaishemale.com":
        return "https://x.com/CreateAIShemale";
      case "https://www.createaiasian.com":
        return "https://x.com/CreateAIAsian";
      case "https://www.createaifurry.com":
        return "https://x.com/CreateAIFurry";
      case "https://www.createaigayporn.com":
        return "https://x.com/CreateAIGayPorn";
      default:
        return "createAIart"; // Default subreddit
    }
  }

  return (
    <div
      className={`fixed bottom-0 w-full border-t-[0.5px] border-[#3C3C3C] bg-[#202124]/80 py-0.5 shadow-2xl backdrop-blur-xl transition-transform duration-300 scrollbar-hide ${
        showFooter ? "translate-y-0" : "translate-y-7"
      }`}
    >
      <div className="m-0 flex overflow-x-scroll whitespace-nowrap py-0 sm:mx-5 sm:items-center sm:justify-center sm:overflow-hidden">
        <div className="my-auto pl-5 text-sm font-medium text-white sm:pl-0 sm:text-sm">
          © 2024 CreatePorn AI
        </div>
        <div className="ml-6 mr-2">
          <LanguagePopover />
        </div>
        <Link
          prefetch={false}
          href={getLocalizedPath("affiliates")}
          className="mx-2 my-auto text-sm text-white sm:text-sm"
        >
          {locale.affiliates}
        </Link>
        <Link
          prefetch={false}
          href={getLocalizedPath("upgrade") + "?ref=footer"}
          className={`${
            user.membership && user?.membership.level === 1 ? "hidden" : "block"
          } mx-2 my-auto text-sm text-white sm:text-sm`}
        >
          {locale.upgrade}
        </Link>
        <a
          href="https://discord.gg/createaiart"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2 my-auto text-sm text-white sm:text-sm"
        >
          Discord
        </a>
        <a
          href={`https://www.reddit.com` + subRedditName()}
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2 my-auto text-sm text-white sm:text-sm"
        >
          Reddit
        </a>
        <a
          href={twitterLink()}
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2 my-auto text-sm text-white sm:text-sm"
        >
          Twitter
        </a>
        <a
          href="https://www.createporn.com/blog/"
          target="_blank"
          rel="noopener noreferrer"
          className="mx-2 my-auto text-sm text-white sm:text-sm"
        >
          {locale.blog}
        </a>
        <Link
          prefetch={false}
          href={getLocalizedPath("faq")}
          className="mx-2 my-auto text-sm text-white sm:text-sm"
        >
          {locale.faq}
        </Link>
        <Link
          prefetch={false}
          href={getLocalizedPath("contact")}
          className="my-auto ml-2 text-sm text-white sm:text-sm"
        >
          {locale.contact}
        </Link>
        <Link
          prefetch={false}
          href={getLocalizedPath("terms")}
          className="my-auto ml-auto text-sm text-white sm:text-sm"
        >
          {locale.terms}
        </Link>
        <Link
          prefetch={false}
          href={getLocalizedPath("privacy")}
          className="my-auto ml-4 text-sm text-white sm:text-sm"
        >
          {locale.privacy}
        </Link>
        <a
          href="https://tally.so/r/3yDbQ4" //"https://docs.google.com/forms/d/e/1FAIpQLSdRzHMF-R5Ie7M579BjdJOAvYaHDMKjvYpN2e7LJP5TsqfzAw/viewform?usp=sf_link"
          target="_blank"
          rel="noopener noreferrer"
          className="my-auto ml-4 text-sm text-white sm:text-sm"
        >
          {locale.dmca}
        </a>
        <Link
          prefetch={false}
          href={getLocalizedPath("2257")}
          className="my-auto ml-4 text-sm text-white sm:text-sm"
        >
          2257
        </Link>
        <Link
          prefetch={false}
          href={getLocalizedPath("billing")}
          className="my-auto ml-4 text-sm text-white sm:text-sm"
        >
          {locale.billingSupport}
        </Link>
        <a
          href="https://docs.google.com/forms/d/e/1FAIpQLSeQ9Lzh-yeojb-0cjyObupf8oQYVqS948gFg80MXSkEABUegQ/viewform?usp=sf_link"
          target="_blank"
          rel="noopener noreferrer"
          className="my-auto ml-4 text-sm text-white sm:text-sm"
        >
          {locale.feedback}
        </a>
      </div>
    </div>
  );
}
