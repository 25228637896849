const siblingPornSites: Array<{ name: string; url: string }> = [
  { name: "AIPorn", url: "https://www.createporn.com" },
  { name: "AIHentai", url: "https://www.createhentai.com" },
  { name: "AIShemale", url: "https://www.createaishemale.com" },
  { name: "AIAsian", url: "https://www.createaiasian.com" },
  { name: "AIGayPorn", url: "https://www.createaigayporn.com" },
  { name: "AIFurry", url: "https://www.createaifurry.com" },
  // { name: "PornGames", url: "https://www.nutaku.net/signup/landing/project-qt/2/?ats=eyJhIjo5OTg3NzIsImMiOjYxNzczMzM2LCJuIjoxLCJzIjoxLCJlIjo5MjM4LCJwIjoyfQ==" },
];

const siblingHentaiSites: Array<{ name: string; url: string }> = [
  { name: "AIPorn", url: "https://www.createporn.com" },
  { name: "AIHentai", url: "https://www.createhentai.com" },
  { name: "AIShemale", url: "https://www.createaishemale.com" },
  { name: "AIAsian", url: "https://www.createaiasian.com" },
  { name: "AIGayPorn", url: "https://www.createaigayporn.com" },
  { name: "AIFurry", url: "https://www.createaifurry.com" },
  // { name: "PornGames", url: "https://www.nutaku.net/signup/landing/project-qt/2/?ats=eyJhIjo5OTg3NzIsImMiOjYxNzczMzM2LCJuIjoxLCJzIjoxLCJlIjo5MjM4LCJwIjoyfQ==" },
];

function removeSiteByName(
  sitesArray: Array<{ name: string; url: string }>,
  siteName: string,
) {
  return sitesArray.filter((site) => site.name !== siteName);
}

export const siteConfigurations = [
  {
    origin: 0,
    category: "Porn",
    siteName: "CreatePorn",
    siteNameFull: "CreatePorn.com",
    siteUrl: "https://www.createporn.com",
    sibilingSites: removeSiteByName(siblingPornSites, "CreatePorn"),
    shortLogo: "https://cdn6.imgpog.com/newLogoShort.svg",
    shortLogoWidth: 30,
    shortLogoHeight: 17,
    fullLogo: "https://cdn6.imgpog.com/newLogoFull.svg",
    fullLogoWidth: 190,
    fullLogoHeight: 18.465,
    mobileLogo: "https://cdn6.imgpog.com/newLogoMobile.svg",
    mobileLogoWidth: 140,
    mobileLogoHeight: 16.165,
  },
  {
    origin: 1,
    category: "GayPorn",
    siteName: "CreateGayPorn",
    siteNameFull: "CreateAIGayPorn.com",
    siteUrl: "https://www.createaigayporn.com",
    sibilingSites: removeSiteByName(siblingPornSites, "AIGayPorn"),
    shortLogo: "https://cdn6.imgpog.com/boyfriendLogoShort.svg",
    shortLogoWidth: 20,
    shortLogoHeight: 20,
    fullLogo: "https://cdn6.imgpog.com/boyfriendLogoFull.svg",
    fullLogoWidth: 220,
    fullLogoHeight: 20.63,
    mobileLogo: "https://cdn6.imgpog.com/boyfriendLogoMobile.svg",
    mobileLogoWidth: 155,
    mobileLogoHeight: 16.75,
  },
  {
    origin: 2,
    category: "Hentai",
    siteName: "CreateHentai",
    siteNameFull: "CreateHentai.com",
    siteUrl: "https://www.createhentai.com",
    sibilingSites: removeSiteByName(siblingHentaiSites, "AIHentai"),
    shortLogo: "https://cdn6.imgpog.com/hentaiLogoShort.webp",
    shortLogoWidth: 30,
    shortLogoHeight: 34.39,
    fullLogo: "https://cdn6.imgpog.com/hentaiLogoFull.webp",
    fullLogoWidth: 200,
    fullLogoHeight: 36.43,
    mobileLogo: "https://cdn6.imgpog.com/hentaiLogoMobile.webp",
    mobileLogoWidth: 140,
    mobileLogoHeight: 29.77,
  },
  {
    origin: 3,
    category: "Furry",
    siteName: "CreateAIFurry",
    siteNameFull: "CreateAIFurry.com",
    siteUrl: "https://www.createaifurry.com",
    sibilingSites: removeSiteByName(siblingHentaiSites, "AIFurry"),
    shortLogo: "https://cdn6.imgpog.com/furryLogoShort.svg",
    shortLogoWidth: 25,
    shortLogoHeight: 31.95,
    fullLogo: "https://cdn6.imgpog.com/furryLogoFull.svg",
    fullLogoWidth: 200,
    fullLogoHeight: 22.47,
    mobileLogo: "https://cdn6.imgpog.com/furryLogoMobile.svg",
    mobileLogoWidth: 140,
    mobileLogoHeight: 18.26,
  },
  {
    origin: 4,
    category: "AsianPorn",
    siteName: "CreateAsian",
    siteNameFull: "CreateAIAsian.com",
    siteUrl: "https://www.createaiasian.com",
    sibilingSites: removeSiteByName(siblingPornSites, "AIAsian"),
    shortLogo: "https://cdn6.imgpog.com/asianLogoShort.svg",
    shortLogoWidth: 25,
    shortLogoHeight: 22.92,
    fullLogo: "https://cdn6.imgpog.com/asianLogoFull.svg",
    fullLogoWidth: 200,
    fullLogoHeight: 21.21,
    mobileLogo: "https://cdn6.imgpog.com/asianLogoMobile.svg",
    mobileLogoWidth: 140,
    mobileLogoHeight: 17.69,
  },
  {
    origin: 5,
    category: "ShemalePorn",
    siteName: "CreateShemale",
    siteNameFull: "CreateAIShemale.com",
    siteUrl: "https://www.createaishemale.com",
    sibilingSites: removeSiteByName(siblingPornSites, "AIShemale"),
    shortLogo: "https://cdn6.imgpog.com/shemaleLogoShort.svg",
    shortLogoWidth: 25,
    shortLogoHeight: 20.46,
    fullLogo: "https://cdn6.imgpog.com/shemaleLogoFull.svg",
    fullLogoWidth: 220,
    fullLogoHeight: 18.51,
    mobileLogo: "https://cdn6.imgpog.com/shemaleLogoMobile.svg",
    mobileLogoWidth: 155,
    mobileLogoHeight: 13.62,
  },
];

const getSiteConfigurations = () => {
  switch (process.env.NEXT_PUBLIC_BASE_URL) {
    case "https://www.createporn.com":
      return siteConfigurations[0];
    case "https://www.createaigayporn.com":
      return siteConfigurations[1];
    case "https://www.createhentai.com":
      return siteConfigurations[2];
    case "https://www.createaifurry.com":
      return siteConfigurations[3];
    case "https://www.createaiasian.com":
      return siteConfigurations[4];
    case "https://www.createaishemale.com":
      return siteConfigurations[5];
    default:
      return siteConfigurations[0]; // Default case, can also be some default generators
  }
};

export function isMainSite() {
  if (siteConfiguration.category === "Porn") {
    return true;
  }
  return false;
}

export const siteConfiguration = getSiteConfigurations();
