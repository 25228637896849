"use client";
import Image from "next/image";
import Link from "next/link";
import { useCallback, useEffect, useState } from "react";
import { useSearchModal } from "./searchModal"; // Update with the correct path
import useScroll from "@/lib/hooks/use-scroll";
import { Gift, Search, Tag } from "lucide-react";
import { useGlobalContext } from "@/app/context/store";
import { getLocalizedPath } from "../shared/formatURL";
import { isMainSite, siteConfiguration } from "../shared/sites/sites";
import { useSignInModal } from "./sign-in-modal";
import UserDropdown from "./user-dropdown";
import { SearchForm } from "@/components/shared/SearchForm";

export default function NavBar({ locale }: { locale: any }) {
  // Get current URL info

  // State variables
  const [show, setShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [isAtTop, setIsAtTop] = useState(true); // New state to track if at the top
  const { setShowSearchModal, SearchModal } = useSearchModal({
    locale: locale,
  });

  // Context and other hooks
  const { user } = useGlobalContext();
  const { SignInModal, setShowSignInModal } = useSignInModal({
    signInModalSignIn: locale.signInModalSignIn,
    signInModalSignInDescription: locale.signInModalSignInDescription,
    signInModalSignInButton: locale.signInModalSignInButton,
  });
  const scrolled = useScroll(50);
  let creditSum = isMainSite()
    ? user.credits.ccBill + user.credits.subStar + user.credits.daily
    : user.credits.subStar + user.credits.daily;
  const siblingSites = siteConfiguration.sibilingSites || [{ name: "", url: "" }];

  let buttonText;
  switch (siteConfiguration.category) {
    case "Porn" || "AsianPorn" || "ShemalePorn":
      buttonText = locale.create;
      break;
    case "Hentai":
      buttonText = locale.createh;
      break;
    case "Furry":
      buttonText = locale.createf;
      break;
    case "GayPorn":
      buttonText = locale.createg;
      break;
    case "ShemalePorn":
      buttonText = locale.creates;
      break;
    default:
      buttonText = locale.create; // Default case if none of the categories match
  }

  // Debounce function
  const debounce = <F extends (...args: any[]) => any>(
    func: F,
    wait: number,
  ): ((...args: Parameters<F>) => void) => {
    let timeoutId: NodeJS.Timeout | null = null;

    return function (...args: Parameters<F>): void {
      const later = () => {
        timeoutId = null;
        func(...args);
      };

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      timeoutId = setTimeout(later, wait);
    };
  };

  // Modified controlNavbar function with debounce
  const controlNavbar = useCallback(() => {
    if (typeof window !== "undefined") {
      const currentScrollY = window.scrollY;

      setIsAtTop(currentScrollY === 0); // Update isAtTop based on scroll position

      if (currentScrollY > 0) {
        // Apply debounce when not at the top
        debounce(() => {
          setShow(currentScrollY < lastScrollY);
        }, 100)();
      } else {
        setShow(true); // Always show when at the top
      }

      setLastScrollY(currentScrollY);
    }
  }, [lastScrollY]);

  function formatViews(number: number) {
    if (number < 1000) {
      return number.toString(); // for numbers less than 1000, just convert to string
    } else if (number < 1000000) {
      return (number / 1000).toFixed(1) + "K"; // for thousands
    } else {
      return (number / 1000000).toFixed(1) + "M"; // for millions
    }
  }

  function dailyClaimAvailable() {
    let lastClaim = new Date(user?.lastDailyCreditClaim || 0);
    let current = new Date();
    let diff = current.getTime() - lastClaim.getTime();
    let hours = diff / 3600000;
    if (hours > 24 || user?.lastDailyCreditClaim === undefined) {
      return true;
    } else {
      return false;
    }
  }

  // Use effect for adding and removing the scroll event listener
  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => window.removeEventListener("scroll", controlNavbar);
  }, [controlNavbar]);

  return (
    <>
      <div className="fixed z-30">
        <SignInModal />
        <SearchModal />
      </div>
      <div
        className={`${
          scrolled ? "bg-[#202124]/80 backdrop-blur-xl" : "bg-[#202124]/100"
        } fixed z-20 w-full border-b-[0px] border-[#3C3C3C] shadow-xl transition-all duration-100 ${
          show ? "top-0 ease-in" : "top-[-6rem] ease-out md:top-[-6rem]"
        }`}
      >
        <div
          className={`flex overflow-x-scroll border-b-[0.5px] border-[#3C3C3C] bg-[#191A1D] px-5 text-sm text-white transition-all duration-500 ease-in-out sm:overflow-x-auto ${
            isAtTop ? "max-h-96 overflow-visible py-0.5" : "max-h-0 overflow-hidden py-0"
          }`}
        >
          {/* <div className="mr-4">ccBill: {user.credits.ccBill}</div>
          <div className="mr-4">subStar: {user.credits.subStar}</div>
          <div className="mr-4">daily: {user.credits.daily}</div> */}
          {user?.membership?.level !== -1 && (
            <Link
              prefetch={false}
              href={getLocalizedPath("reward-calendar")}
              className="flex flex-row whitespace-nowrap pr-4 md:pr-6"
            >
              <Gift
                size={14}
                className={`${
                  dailyClaimAvailable() ? "text-[#FFCC18]" : "text-white"
                } my-auto mr-2`}
              />
              <div>{locale.dailyRewards}</div>
            </Link>
          )}
          {siblingSites.map((site: any) => {
            return (
              //opacity-75
              <a key={site.name} href={site.url} className="pr-4 md:pr-6">
                {site.name}
              </a>
            );
          })}
        </div>
        <div className={`flex h-12 max-w-[88rem] items-center px-5 md:h-14 xl:mx-auto`}>
          <div className="flex w-full flex-col md:flex-row">
            <div className="row flex w-full items-center">
              {user?.membership?.level === -1 ? (
                <Link
                  prefetch={false}
                  href={getLocalizedPath("upgrade")}
                  className="mr-auto flex items-center justify-between rounded-md border-[0.5px] border-[#3C3C3C] bg-[#2B2D31] px-[10px] py-[2px] text-white md:hidden"
                >
                  <div className="animate-text bg-gradient-to-r from-[#FF6200] via-[#FFCC18] to-[#FFCC18] bg-clip-text text-sm font-black text-transparent underline">
                    VIP
                  </div>
                </Link>
              ) : (
                <Link
                  prefetch={false}
                  href={
                    user?.membership?.level === 0 || user?.membership?.level === undefined
                      ? getLocalizedPath("upgrade")
                      : getLocalizedPath("credits")
                  }
                  className="mr-auto flex items-center justify-between rounded-md border-[0.5px] border-[#3C3C3C] bg-[#2B2D31] px-[4px] py-[2px] text-white md:hidden"
                >
                  {/* <div className="text-[8px] ml-0.5 mr-1">💎</div> */}
                  <Image
                    className={`rounded-md object-cover ${
                      formatViews(creditSum) === "0" ? "mx-[6px] my-[2.75px]" : ""
                    }`}
                    src={
                      isMainSite()
                        ? "https://cdn6.imgpog.com/ruby.webp"
                        : "https://cdn6.imgpog.com/diamond.webp"
                    }
                    alt="Gem"
                    width={14}
                    height={14}
                    unoptimized
                  />
                  <div
                    className={`mr-0.5 text-sm font-semibold ${
                      formatViews(creditSum) === "0" ? "hidden" : "ml-1"
                    }`}
                  >
                    {formatViews(creditSum)}
                  </div>
                </Link>
              )}
              <Link
                prefetch={false}
                href={getLocalizedPath("")}
                className="flex h-full items-center font-display text-xl"
              >
                <Image
                  src={siteConfiguration.fullLogo}
                  alt="AI Porn"
                  width={siteConfiguration.fullLogoWidth}
                  height={siteConfiguration.fullLogoHeight}
                  className="mr-2 hidden cursor-pointer rounded-sm lg:block"
                  unoptimized
                />
                <Image
                  src={siteConfiguration.shortLogo}
                  alt="AI Porn"
                  width={siteConfiguration.shortLogoWidth}
                  height={siteConfiguration.shortLogoHeight}
                  className="mr-2 hidden cursor-pointer rounded-sm md:block lg:hidden"
                  unoptimized
                />
                <Image
                  src={siteConfiguration.mobileLogo}
                  alt="AI Porn"
                  width={siteConfiguration.mobileLogoWidth}
                  height={siteConfiguration.mobileLogoHeight}
                  className="block cursor-pointer rounded-sm md:hidden"
                  unoptimized
                />
              </Link>
              <SearchForm
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                locale={locale}
                formClassName={"relative mx-2 hidden md:block"}
                inputClassName={
                  "w-full rounded-md border-[0.5px] border-[#3C3C3C] bg-[#2B2D31] px-4 py-[7px] pl-4 pr-8 text-sm text-white placeholder-white placeholder-opacity-75 focus:outline-none"
                }
                buttonClassName={"absolute right-2 top-1/2 -translate-y-1/2 transform text-white"}
              />
              <button className="ml-0 mr-4 flex hidden w-auto flex-row whitespace-nowrap rounded-md border-[0.5px] border-[#3C3C3C] bg-[#2B2D31] px-2 py-[7.45px] text-white hover:brightness-105 md:block">
                <Link
                  prefetch={false}
                  aria-label="Search By Tags"
                  href={getLocalizedPath("tags")}
                  className="flex flex-row"
                >
                  <Tag className="my-auto mr-0.5 h-[14px] text-[#E53155]" />
                  <div className="mr-1 text-sm">{locale.tags}</div>
                </Link>
              </button>
              {/* <button className="ml-2 mr-8 flex hidden w-auto flex-row whitespace-nowrap rounded-md border-[0.5px] border-[#3C3C3C] bg-[#2B2D31] px-2 py-[7.5px] text-white hover:brightness-105 md:block">
                <a
                  href="http://tinyurl.com/3r36dhst"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex flex-row"
                >
                  <Gamepad2 className="my-auto mr-0.5 h-4 text-[#E53155]" />
                  <div className="mr-1 text-sm">{locale.games}</div>
                </a>
              </button> */}
              <Link
                prefetch={false}
                href={getLocalizedPath("create")}
                className="ml-auto hidden h-full items-center whitespace-nowrap whitespace-nowrap font-display text-sm text-white sm:text-base md:flex"
              >
                {buttonText}
              </Link>
              <Link
                prefetch={false}
                href={getLocalizedPath("upgrade") + "?ref=navbar"}
                className={`${
                  user.membership && user?.membership.level === 1 ? "md:hidden" : "md:flex"
                } ml-auto hidden h-full animate-text items-center whitespace-nowrap bg-gradient-to-r from-[#FF6200] via-[#FFCC18] to-[#FFCC18] bg-clip-text font-display text-base font-medium text-transparent underline sm:ml-8`}
              >
                {locale.upgrade}
              </Link>
              <div className="ml-auto flex flex-row md:ml-0 md:w-auto">
                <button
                  aria-label="Search"
                  onClick={() => setShowSearchModal(true)}
                  className="flex md:hidden"
                >
                  <Search className="my-auto text-white" size={22.5} />
                </button>
                <div
                  className={`my-auto ${
                    user.membership && user?.membership.level === 1 ? "ml-auto sm:ml-0" : "flex"
                  }`}
                >
                  {user._id !== "none" ? (
                    <UserDropdown user={user} locale={locale} />
                  ) : (
                    <div>
                      <button
                        className="ml-4 hidden whitespace-nowrap rounded-full bg-[#DD0772] p-1.5 px-4 text-xs font-bold text-white shadow-2xl transition-all hover:text-white hover:brightness-105 sm:ml-8 sm:text-sm md:flex"
                        onClick={() => setShowSignInModal(true)}
                      >
                        {locale.signIn}
                      </button>
                      <button
                        onClick={() => setShowSignInModal(true)}
                        className="ml-4 flex items-center justify-center overflow-hidden rounded-full transition-all duration-75 focus:outline-none focus:ring-0 active:scale-95 sm:h-8 sm:w-8 md:hidden"
                      >
                        <Image
                          alt="profile picture"
                          className="my-auto"
                          src={"https://cdn6.imgpog.com/profileIcon.webp"}
                          width={22.25}
                          height={22.25}
                          unoptimized
                        />
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <Link
                prefetch={false}
                href={
                  user?.membership?.level === 0 || user?.membership?.level === undefined
                    ? getLocalizedPath("upgrade")
                    : getLocalizedPath("credits")
                }
                className={`ml-0 flex hidden items-center justify-between rounded-md border-[0.5px] border-[#3C3C3C] bg-[#2B2D31] px-[4px] py-[2px] text-white md:ml-8 ${
                  user?.membership?.level === -1 ? "hidden" : "md:flex"
                } cursor-pointer`}
              >
                {/* <div className="text-[8px] ml-0.5 mr-1">💎</div> */}
                <Image
                  className={`rounded-md object-cover ${
                    formatViews(creditSum) === "0" ? "m-[2.75px]" : ""
                  }`}
                  src={
                    isMainSite()
                      ? "https://cdn6.imgpog.com/ruby.webp"
                      : "https://cdn6.imgpog.com/diamond.webp"
                  }
                  alt="Gem"
                  width={14}
                  height={14}
                  unoptimized
                />
                <div
                  className={`mr-0.5 text-sm font-semibold ${
                    formatViews(creditSum) === "0" ? "hidden" : "ml-1"
                  }`}
                >
                  {formatViews(creditSum)}
                </div>
              </Link>
              <Link
                prefetch={false}
                href={getLocalizedPath("reward-calendar")}
                className={`hidden ${
                  user?.membership?.level === -1 ? "hidden" : "md:flex"
                } ml-2 rounded-md border-[0.5px] border-[#3C3C3C] bg-[#2B2D31] px-2 py-[3.8px]`}
              >
                <Gift
                  size={16}
                  className={`${dailyClaimAvailable() ? "text-[#FFCC18]" : "text-white"}`}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
