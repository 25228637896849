"use client";
import { useEffect, useState } from "react";
import { usePathname } from "next/navigation";
import Image from "next/image";
import rta from "../../public/images/rta.jpg";

export default function Form({ locale }: { locale: any }) {
  const [isShown, setIsShown] = useState(false);
  const [isDevDomain, setIsDevDomain] = useState(false);
  const [password, setPassword] = useState("");
  const pathname = usePathname();
  const isHomePage = pathname === "/" || pathname === locale.path.slice(0, -1);

  const onSubmit = (state: any) => {
    if (state) {
      localStorage.setItem("agreedTOS", "true");
      window.scrollTo(0, 0);
      setIsShown(false);
    } else {
      window.location.href = "https://www.google.com";
    }
  };

  const onPasswordSubmit = () => {
    if (password === "squids69") {
      localStorage.setItem("developerAccess", "true");
      setIsShown(false);
    } else {
      alert("Incorrect password");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("agreedTOS") === null && isHomePage) {
      setIsShown(true);
    }
    // if (window.location.hostname === "www.8porn.ai") {
    //   setIsDevDomain(true);
    //   if (!localStorage.getItem("developerAccess")) {
    //     setIsShown(true);
    //   }
    // } else {
    //   if (localStorage.getItem("agreedTOS") === null && isHomePage) {
    //     setIsShown(true);
    //   }
    // }
  }, []);

  return (
    //Enter Developer Password
    <>
      {isShown && (
        <div className="fixed inset-0 flex bg-opacity-10 px-4 backdrop-blur-2xl">
          <div className="m-auto flex max-w-[400px] flex-col rounded-md border-[0.5px] border-[#3C3C3C] bg-[#2B2D31] p-5">
            {isDevDomain ? (
              <>
                <div className="mb-4 text-center text-sm font-medium text-white">
                  We are currently rolling out some massive changes, the
                  maintance will complete within a few hours!
                </div>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="mb-4 rounded-md p-2"
                />
                <button
                  onClick={onPasswordSubmit}
                  className="mt-4 w-auto cursor-pointer rounded-full bg-[#DD0772] p-1.5 px-4 text-sm font-medium text-white hover:brightness-105 focus:outline-none focus:ring-0"
                >
                  Submit
                </button>
              </>
            ) : (
              <>
                <div className="mb-2 text-left text-center text-sm font-medium text-white">
                  {locale.adultWarning1}
                  <br />
                  <br />
                  {locale.adultWarning2}
                </div>
                <div className="mx-auto flex flex-row">
                  <button
                    onClick={() => onSubmit(true)}
                    className="mr-2 mt-4 w-auto cursor-pointer rounded-full bg-[#DD0772] p-1.5 px-4 text-sm font-medium text-white hover:brightness-105 focus:outline-none focus:ring-0"
                  >
                    {locale.accept}
                  </button>
                  <button
                    onClick={() => onSubmit(false)}
                    className="ml-2 mt-4 w-auto cursor-pointer rounded-full border-[0.5px] border-[#3E3E3E] bg-[#3E3E3E] p-1.5 px-4 text-sm font-medium text-white hover:brightness-105 focus:outline-none focus:ring-0"
                  >
                    {locale.decline}
                  </button>
                </div>
                <div className="mt-6 flex h-auto flex-row border-t border-[#3C3C3C] pt-4">
                  <div className="my-auto ml-auto mr-2 text-sm text-white">
                    © 2024 CreatePorn AI
                  </div>
                  <a
                    href="https://www.rtalabel.org/"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    className="cursor pointer relative my-auto mr-auto h-[40px] w-[78px] overflow-hidden"
                  >
                    <Image
                      src={"https://cdn6.imgpog.com/rta.webp"}
                      alt="RTA"
                      width={80} // These dimensions are now slightly larger than the container
                      height={50}
                      draggable="false"
                      className="absolute bottom-[-1.5px] right-[-1px] ml-[-1px] mt-[-1.5px]"
                    />
                  </a>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
}
