type LocaleListType = {
  [key: string]: { shortForm: string; longForm: string };
};

export const localeList: LocaleListType = {
  "en-US": { shortForm: "EN", longForm: "English" },
  "ja-JP": { shortForm: "日本語", longForm: "日本語" },
  "ko-KR": { shortForm: "한국어", longForm: "한국어" },
  "zh-CN": { shortForm: "中文", longForm: "中文" },
  "zh-TW": { shortForm: "國語", longForm: "國語" },
  "es-MX": { shortForm: "ES", longForm: "Español" },
  "de-DE": { shortForm: "DE", longForm: "Deutsch" },
  "fr-CA": { shortForm: "FR", longForm: "Français" },
  "ru-RU": { shortForm: "RU", longForm: "Русский" },
  "pt-BR": { shortForm: "PT", longForm: "Português" },
};
