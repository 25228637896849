import React, { useState } from "react";
import Popover from "@/components/shared/popover";
import { localeList } from "../../dictionaries/localeList";
import { usePathname, useSearchParams } from "next/navigation";
import { Languages } from "lucide-react";
import cookie from "cookie";
import Link from "next/link";

export const LanguagePopover = () => {
  const pathname = usePathname();
  const searchParams = new URLSearchParams(useSearchParams());

  // const [openDesktopPopover, setOpenDesktopPopover] = useState(false);
  const [openMobilePopover, setOpenMobilePopover] = useState(false);

  // Choose the correct state based on the 'desktop' prop
  // const isOpen = desktop ? openDesktopPopover : openMobilePopover;
  // const setOpen = desktop ? setOpenDesktopPopover : setOpenMobilePopover;

  const isOpen = openMobilePopover;
  const setOpen = setOpenMobilePopover;

  const [lang, country] = pathname.split("/").slice(1, 3);
  const defaultLang = lang || "en";
  const defaultCountry = country || "US";
  const localeKey = `${defaultLang}-${defaultCountry.toUpperCase()}`;
  const effectiveLocaleKey = localeList.hasOwnProperty(localeKey)
    ? localeKey
    : "en-US";

  return (
    <Popover
      content={
        <div className="w-full rounded-md bg-[#202124] p-2 md:w-40">
          {Object.keys(localeList).map((key) => {
            const remainingPath = pathname.split("/").slice(3).join("/");
            const newPath = `/${key.replace("-", "/").toLowerCase()}${
              remainingPath ? "/" + remainingPath : ""
            }${searchParams.toString() ? "?" + searchParams.toString() : ""}`;
            return (
              <Link
                prefetch={false}
                key={key}
                as={newPath}
                rel="alternative"
                hrefLang={key}
                href={`/${remainingPath ? "/" + remainingPath : ""}`}
                onClick={() => {
                  // Serialize the cookie string using the `cookie` library
                  const serializedCookie = cookie.serialize("locale", key, {
                    path: "/",
                    maxAge: 365 * 24 * 60 * 60 * 999, // Max age in seconds
                  });
                  // Set the cookie in the browser
                  document.cookie = serializedCookie;
                  // Close the modal or dropdown as before
                  setOpen(false);
                }}
                className="flex w-full items-center justify-start space-x-2 rounded-md p-2 text-left text-sm text-white transition-all duration-75 hover:bg-[#4d4f59] active:bg-[#4d4f59]"
              >
                {localeList[key].longForm}
              </Link>
            );
          })}
        </div>
      }
      openPopover={isOpen}
      setOpenPopover={setOpen}
    >
      {/* <button
        onClick={() => setOpen(!isOpen)}
        title="Change Language"
        className="ml-0 md:ml-8 flex w-auto items-center justify-between rounded-md border-[0.5px] border-[#3C3C3C] bg-[#2B2D31] py-[4px] text-xs transition-all duration-75 hover:brightness-105"
      >
        <p className="flex w-7 flex-row justify-center text-center text-white">
          <Languages className="my-auto h-4 w-4" />
        </p>
      </button> */}
      <button
        onClick={() => setOpen(!isOpen)}
        title="Change Language"
        className="flex w-auto items-center justify-between text-sm text-white hover:brightness-105"
      >
        <Languages className="h-3 w-3" />
        <div className="ml-2">{localeList[effectiveLocaleKey].longForm}</div>
      </button>
    </Popover>
  );
};
